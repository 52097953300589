import {Application} from '../tab-applications/application.types'

export const colors: Array<{label: string, value: string, hexValue: string, palette: string}> = [
    {label: 'Zinc', value: 'zinc', hexValue: '#52525b', palette: 'neutral'},
    {label: 'Slate', value: 'slate', hexValue: '#64748b', palette: 'azure'},
    {label: 'Stone', value: 'stone', hexValue: '#78716c', palette: 'neutral'},
    {label: 'Gray', value: 'gray', hexValue: '#6b7280', palette: 'neutral'},
    {label: 'Neutral', value: 'neutral', hexValue: '#737373', palette: 'neutral'},
    {label: 'Red', value: 'red', hexValue: '#dc2626', palette: 'red'},
    {label: 'Rose', value: 'rose', hexValue: '#e11d48', palette: 'magenta'},
    {label: 'Orange', value: 'orange', hexValue: '#f97316', palette: 'orange'},
    {label: 'Green', value: 'green', hexValue: '#16a34a', palette: 'spring-green'},
    {label: 'Blue', value: 'blue', hexValue: '#2563eb', palette: 'azure'},
    {label: 'Yellow', value: 'yellow', hexValue: '#facc15', palette: 'chartreuse'},
    {label: 'Violet', value: 'violet', hexValue: '#7c3aed', palette: 'violet'},
    {label: 'Attentive', value: 'attentive', hexValue: '#4FFF83', palette: 'spring-green'},
    {label: 'Spring Green', value: 'spring-green', hexValue: '#4FFF83', palette: 'spring-green'},
    {label: 'Azure', value: 'azure', hexValue: '#007FFF', palette: 'azure'},
    {label: 'Magenta', value: 'magenta', hexValue: '#FF00FF', palette: 'magenta'},
    {label: 'Chartreuse', value: 'chartreuse', hexValue: '#7FFF00', palette: 'chartreuse'},
    {label: 'Cyan', value: 'cyan', hexValue: '#00FFFF', palette: 'cyan'},
]

export const colorMap = colors.reduce((acc, {value, palette, hexValue}) => {
    acc[value] = {palette, value, hexValue}
    return acc
}, {} as Record<string, {palette: string, value: string, hexValue: string}>)

export const logos: Array<{path: string, title: string}> = Array.from({length: 13}, (v, i) => ({
    path: `/logos/${i + 1}.png`,
    title: `Logo ${i + 1}`
}))


export const introImages: Array<{path: string, title: string}> = Array.from({length: 7}, (v, i) => ({
    path: `/intro-image/${i + 1}.png`,
    title: `Image ${i + 1}`
}))

export enum DomainOption {
    EXISTS = 'exists',
    BUY = 'buy'
}

export const domainOptions = [
    {label: 'I already own a domain', value: DomainOption.EXISTS},
    {label: 'Set up new domain for me', value: DomainOption.BUY}
]


export const products: Array<{title: string, desc: string, imgUrl: string, formControlName: string, domainFomControlName: string, value: Application}> = [
    {
        title: 'Member Manager',
        value: Application.MEMBER_MANAGER,
        desc: 'Effortlessly manage healthcare product sales with an intuitive platform that unifies client, agency, broker, and member data. Track client status, coverage, and payments, while integrating with payroll systems to streamline operations and enhance service delivery.',
        imgUrl: '/products/members-manager.png',
        formControlName: 'members_manager',
        domainFomControlName: 'members_manager_domain'
    },
    {
        title: 'Proposals Manager',
        value: Application.PROPOSAL_MANAGER,
        desc: 'Streamline proposal and invoice creation with an all-in-one platform for client management. Track, create, and deliver documents with ease, integrated payroll processing, and QuickBooks compatibility for financial accuracy. Enhance efficiency and client interactions through a user-friendly interface.',
        imgUrl: '/products/proposals-manager.png',
        formControlName: 'proposals_manager',
        domainFomControlName: 'proposals_manager_domain'
    },
    {
        title: 'Healthcare Portal',
        value: Application.HEALTHCARE_PORTAL,
        desc: 'Discover a personalized healthcare portal offering tailored articles, videos, and training programs for managing medical conditions. Connect with healthcare providers via chat, video calls, or schedule consultations, while easily managing health records and staying informed about your health journey.',
        imgUrl: '/products/healthcare-portal.png',
        formControlName: 'healthcare_portal',
        domainFomControlName: 'healthcare_portal_domain'
    },
    {
        title: 'Healthcare Mobile App',
        value: Application.HEALTHCARE_MOBILE,
        desc: 'A mobile healthcare app offering personalized articles, videos, and training for managing conditions. Connect with providers via chat, video calls, or schedule consultations. Easily manage health records, track progress, and stay informed on your health journey—all in one secure platform.',
        imgUrl: '/products/app.jpg',
        formControlName: 'healthcare_mobile_app',
        domainFomControlName: 'healthcare_mobile_app_domain'
    }
]

export const vendors: IVendor[] = [
    {
        title: 'UCM Digital health',
        desc: '24/7 Telehealth Platform Provides virtual medicine with a personal touch. Diagnose, prescribe medication, order labs, make referrals, and triage all conditions. Available 24/7/365 via telephone, mobile, tablet, or computer.',
        actions: [
            { text: 'Chat with a Doctor', icon: 'chat' },
            { text: 'Call a Doctor', icon: 'call' },
            { text: 'Schedule a meeting with a Doctor', icon: 'schedule' }
        ],
        about: 'UCM Digital Health delivers an innovative, whole-person care solution that adapts to patients\' needs and schedules. Our platform combines a seamless digital front door, a dedicated multi-disciplinary team of healthcare professionals, and 24/7 telehealth triage, treatment, and navigation services. We provide comprehensive patient care that spans emergent and urgent care, primary and specialty care, behavioral health services, and more. Care starts digitally, but can transition smoothly to in-person care, ensuring a unified and effortless patient journey. UCM’s blend of clinical expertise, advanced technology, and compassionate care offers a transformative healthcare experience for patients, providers, insurers, and employers alike.',
        imageUrl: '/vendors/ucm-digital.png',
        formControlName: 'ucm_digital',
        website: 'https://ucmdigitalhealth.com/'
    },
    {
        title: 'Anura',
        desc: 'Take a selfie, know your healthie! By using your phone\'s video camera, Anura can measure, track and record your general wellness based on your facial blood flow.',
        actions: [
            { text: 'Take a Video and know your Healthie', icon: 'videocam' }
        ],
        about: 'Anura provides a revolutionary health assessment platform that delivers accurate insights into your health simply by analyzing facial blood flow patterns through video. By using advanced AI-powered technology, Anura enables users to monitor and track vital signs such as heart rate, stress levels, and more, all in real-time. Accessible from your mobile device or desktop, it offers a seamless, non-invasive health checkup experience. Anura empowers users to gain critical health insights quickly and conveniently, supporting both individuals and organizations in enhancing health and wellness outcomes. Whether for personal monitoring, corporate wellness programs, or healthcare professionals seeking efficient screening tools, Anura transforms health assessments with its accessible, accurate, and user-friendly approach.',
        imageUrl: '/vendors/anura.png',
        formControlName: 'anura',
        website: 'https://www.anura.io/'
    },
    {
        title: 'Corp Care',
        desc: 'CorpCare provides a comprehensive Program focused on supporting the mental and emotional well-being.',
        actions: [
            { text: 'Connect with a Counselor', icon: 'support' },
            { text: 'Schedule a Meeting with a Counselor', icon: 'schedule' }
        ],
        about: 'CorpCare is dedicated to enhancing mental and emotional health through a holistic Employee Assistance Program (EAP) designed to meet diverse needs. Offering 24/7 confidential access to certified support professionals, CorpCare is there when help is needed most—whether during a crisis or for ongoing challenges. Our services extend to a range of personal and workplace concerns, including mental health support for issues like depression, stress, and substance misuse, relationship and family guidance, parenting resources, and work-related challenges such as supervisory referrals. We also provide legal, financial, eldercare, and childcare consultations, ensuring well-rounded assistance for every aspect of life. With CorpCare, employees and their families have the support and resources they need to thrive, both personally and professionally.',
        imageUrl: '/vendors/corpcare.png',
        formControlName: 'corp_care',
        website: 'https://www.corpcare.com/'
    },
    {
        title: 'Our Relationship',
        desc: 'The OurRelationship program is available online or through the app stores. It includes 8-10 hours of tailored content and activities designed to uncover, understand, and solve the relationship issues that you\'re facing.',
        actions: [
            { text: 'Join a Relationship Program', icon: 'group' }
        ],
        about: 'The OurRelationship program offers a flexible, accessible, and affordable approach to relationship improvement, available online or through mobile app stores. Over 8-10 hours of interactive content and activities, the program helps individuals and couples uncover, understand, and address their unique relationship challenges. Designed to be completed within four to six weeks, the program offers self-directed learning or the option to work with a dedicated coach. Coaching support can include an initial orientation session or weekly 20-minute calls, conveniently scheduled during the day, evening, or weekends to fit your needs. Adapted to support all couples—including LGBTQ+, heterosexual, and military couples—the OurRelationship program can also be completed individually if a partner is unavailable. Whether improving communication, resolving conflicts, or enhancing intimacy, OurRelationship provides a proven, accessible alternative to traditional couple therapy, fostering stronger, healthier connections.',
        imageUrl: '/vendors/our-relationship.png',
        formControlName: 'ourRelationship',
        website: 'https://ourrelationship.com/'
    },
    {
        title: 'VRS Freedom 365',
        desc: 'A simple solution that integrates proven recovery methods to help you, your loved ones, your employees, your students or your patients.',
        actions: [
            { text: 'Get in touch with a Counselor', icon: 'support' }
        ],
        about: 'VRS Freedom 365 offers a revolutionary approach to addiction recovery with the world’s first interactive virtual recovery system. Designed to support lasting change, this comprehensive one-year program combines a structured video course with hundreds of insightful videos addressing every aspect of substance use and PTSD. Accessible anytime, VRS Freedom 365 empowers individuals to navigate their recovery journey on their own terms, providing tools, guidance, and continuous support through a flexible, engaging digital platform. Whether at home or on-the-go, users can engage with expertly crafted content tailored to help them overcome addiction and build a path to sustained health and wellness.',
        imageUrl: '/vendors/vrs.png',
        formControlName: 'vrs_freedom365',
        website: 'https://vrsfreedom365.com/'
    },
    {
        title: 'Mayo Clinic',
        desc: 'Programs designed by leading experts for all aspects of a healthy life.',
        actions: [],
        about: '',
        imageUrl: '/vendors/mayo-clinic.svg',
        formControlName: 'mayo_clinic',
        website: 'https://www.24alife.com/'
    }
];


export interface IVendor {
    title: string;
    desc: string;
    actions: Array<{ text: string, icon: string }>;
    about: string;
    imageUrl: string;
    formControlName?: string;
    website?: string;
}
